'use client'

//Libraries
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { TriangleRightIcon } from '@radix-ui/react-icons'
import React, { useEffect, useState } from 'react'
import { css, cva, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

import { TypographyVariants } from '../../utils/typescript-utils'
import { Icon } from '../Icon'
import { Text } from '../Text'

export interface DropdownItemType {
  /**
   * Id of the dropdown item.
   */
  key: string

  /**
   * If true, disables the menu item.
   * Defaults to false
   */
  disabled?: boolean

  /**
   * The icon to display for the dropdown item. Shown on left side of text.
   */
  reactIcon?: React.ReactNode

  /**
   * The text to display for the dropdown item.
   */
  label?: string

  css?: SystemStyleObject

  type?: 'divider' | 'text'
}

export interface DropdownMenuItemType extends DropdownItemType {
  children?: DropdownItemType[]
}

export interface DropdownMenuProps {
  /**
   * The options list to show.
   */
  options?: DropdownMenuItemType[]

  /**
   * The max height of the list Container.
   */
  maxHeight?: number

  /**
   * An offset in pixels from the "start" or "end" alignment options.
   */
  alignOffset?: number

  /**
   * The distance in pixels from the trigger.
   */
  sideOffset?: number

  /**
   * The distance in pixels from the edges.
   */
  collisionPadding?: number

  initiallySelectedOption?: DropdownItemType

  /**
   * The preferred side of the trigger to render against when open.
   */
  side?: 'top' | 'right' | 'bottom' | 'left'

  /**
   * The preferred alignment against the trigger.
   */
  align?: 'start' | 'center' | 'end'

  /**
   * Give css class name to dropdown component.
   */
  className?: string

  /**
   * Give css property to dropdown component trigger.
   */
  triggerCss?: SystemStyleObject

  /**
   * Give css property to dropdown component content.
   */
  contentMenuCss?: SystemStyleObject

  containerCss?: SystemStyleObject

  /**
   * Text variant for all items.
   */
  textVariant?: TypographyVariants

  /**
   * The main color of the dropdown
   */
  color?: string

  /**
   * Content for button trigger.
   */
  children?: React.ReactNode

  asChild?: boolean

  /**
   * Return selected menu option.
   */
  onSelectMenuItem?: (value: DropdownMenuItemType, e: Event) => void

  /**
   * Return selected sub menu option.
   */
  onSelectSubMenuItem?: (value: DropdownMenuItemType) => void

  /**
   * Return open state of the dropdown.
   */
  onOpenChange?: (value: boolean) => void
}

export const DropdownMenu = React.forwardRef<HTMLDivElement, DropdownMenuProps>(
  (
    {
      textVariant = 'subtitle2',
      options = [],
      maxHeight = 300,
      className = '',
      triggerCss = {},
      contentMenuCss = {},
      containerCss = {},
      children,
      side = 'bottom',
      align = 'start',
      color = '$gs3',
      sideOffset = 4,
      alignOffset = 0,
      asChild = false,
      collisionPadding = 0,
      onOpenChange,
      onSelectMenuItem,
      onSelectSubMenuItem,
      initiallySelectedOption,
    },
    forwardedRef,
  ) => {
    const [openDropdown, setOpenDropdown] = useState(false)
    const [selectedMenuItem, setSelectedMenuItem] = useState<DropdownMenuItemType | undefined>(initiallySelectedOption)
    const [selectedSubOption, setSelectedSubOption] = useState<DropdownItemType>()
    const showActiveState = !!initiallySelectedOption

    useEffect(() => {
      setSelectedMenuItem(initiallySelectedOption)
    }, [initiallySelectedOption])

    const handleMenuClick = (value: DropdownMenuItemType, e: Event) => {
      if (showActiveState) {
        const item = options.find(option => option.key === value.key)
        if (item) setSelectedMenuItem(undefined)
      }
      if (onSelectMenuItem) onSelectMenuItem(value, e)
    }

    const handleSubClick = (value: DropdownItemType) => {
      setSelectedSubOption(value)
      if (onSelectSubMenuItem) onSelectSubMenuItem(value)
    }

    const handleOpen = (state: boolean) => {
      if (onOpenChange) onOpenChange(state)
      setOpenDropdown(state)
    }

    return (
      <DropdownMenuPrimitive.Root onOpenChange={handleOpen} open={openDropdown}>
        <DropdownMenuPrimitive.Trigger asChild={asChild} className={cx(outerTrigger, css(triggerCss))}>
          {children}
        </DropdownMenuPrimitive.Trigger>
        <div className={css(container, containerCss)} id="mike-test">
          <DropdownMenuPrimitive.Content
            className={cx(
              dropdownMenuContentOuter,
              css(
                {
                  backgroundColor: color as any, // TODO: change so that colors are the values of colors
                },
                contentMenuCss,
              ),
              className,
            )}
            style={{
              maxHeight: `${maxHeight}px`,
            }}
            id="mike-test2"
            sideOffset={sideOffset}
            alignOffset={alignOffset}
            side={side}
            align={align}
            ref={forwardedRef}>
            {options?.map((option: DropdownMenuItemType) =>
              option.type !== 'divider' ? (
                !option.children ? (
                  <DropdownMenuPrimitive.Item
                    disabled={option.disabled}
                    key={option.key}
                    className={cx(
                      dropdownMenuItemOuter({
                        type: option.type,
                      }),
                      css(option.css),
                      showActiveState && selectedMenuItem && option.key == selectedMenuItem.key ? 'active' : '',
                    )}
                    onSelect={e => {
                      handleMenuClick(option, e)
                    }}>
                    {option.reactIcon && <Icon reactIcon={option.reactIcon} size={20} css={dropdownIcon} />}
                    <Text variant={textVariant} css={textStyled}>
                      {option.label}
                    </Text>
                  </DropdownMenuPrimitive.Item>
                ) : (
                  <DropdownMenuPrimitive.Root key={option.key}>
                    <DropdownMenuPrimitive.Trigger className={innerTrigger}>
                      {/* <span className="material-icons-outlined">{option?.iconName}</span>  TODO: // fix this */}
                      <Text variant={textVariant} css={textStyled}>
                        {option.label}
                      </Text>
                      <TriangleRightIcon />
                    </DropdownMenuPrimitive.Trigger>
                    <DropdownMenuPrimitive.Content
                      className={cx(
                        dropdownMenuContentInner,
                        css({
                          backgroundColor: color as any, // TODO: change so that colors are the values of colors
                        }),
                      )}
                      style={{ maxHeight: `${maxHeight}px` }}
                      side="bottom"
                      avoidCollisions={true}
                      collisionPadding={collisionPadding}
                      sideOffset={sideOffset}>
                      {option.children?.map((subOption: DropdownItemType) =>
                        subOption.type === 'divider' ? (
                          <DropdownMenuPrimitive.Separator
                            key={'separator' + subOption.key}
                            className={dropdownMenuSeparator}
                          />
                        ) : (
                          <DropdownMenuPrimitive.Item
                            className={cx(
                              dropdownMenuItemInner,
                              showActiveState && selectedSubOption && subOption.key == selectedSubOption.key
                                ? 'active'
                                : '',
                            )}
                            disabled={subOption.disabled}
                            key={subOption.key}
                            onSelect={e => {
                              handleSubClick(subOption)
                            }}>
                            {subOption.reactIcon && (
                              <Icon reactIcon={subOption.reactIcon} size={20} css={dropdownIcon} />
                            )}

                            <Text variant={textVariant} css={textStyled}>
                              {subOption.label}
                            </Text>
                          </DropdownMenuPrimitive.Item>
                        ),
                      )}
                    </DropdownMenuPrimitive.Content>
                  </DropdownMenuPrimitive.Root>
                )
              ) : (
                <DropdownMenuPrimitive.Separator key={option.key} className={dropdownMenuSeparator} />
              ),
            )}
          </DropdownMenuPrimitive.Content>
        </div>
      </DropdownMenuPrimitive.Root>
    )
  },
)

DropdownMenu.displayName = 'DropdownMenu'

const dropdownMenuContentOuter = css({
  color: '$gs12',
  width: '$full',
  overflowX: 'auto',
  boxShadow:
    '[{sizes.$0} {sizes.$3} {sizes.$10} -{sizes.$3} {colors.$b7}, {sizes.$0} {sizes.$3} {sizes.$5} -{sizes.$4} {colors.$b9}]',
  '&[data-side="top"]': {
    boxShadow: '[{colors.$b11} 0px -5px 5px -3px, {colors.$b11} 0px -5px 10px 1px, {colors.$b11} 0px -3px 14px 2px]',
  },
  '&[data-side="bottom"]': {
    boxShadow: '[{colors.$b11} -2px 5px 5px -3px, {colors.$b11} 0px 5px 10px 1px, {colors.$b11} 0px 3px 14px 2px]',
  },
})

const dropdownMenuItemOuter = cva({
  base: {
    position: 'relative',
    display: 'flex',
    cursor: 'pointer',
    py: '$2',
    px: '$4',
    alignItems: 'center',
    outline: 'none',
    _active: {
      content: '" "',
      top: '$0',
      left: '$0',
      right: '$0',
      bottom: '$0',
      borderWidth: '[2px]',
      borderStyle: 'solid',
      borderColor: '$sec',
      backgroundColor: '$gs6',
    },
    _before: {
      content: '" "',
      borderStyle: 'solid',
      borderWidth: '$0',
      borderBottomWidth: '$1',
      borderColor: '$none',
      position: 'absolute',
      mx: '$1',
      inset: '$0',
    },
    _after: {
      content: '" "',
      borderStyle: 'solid',
      borderWidth: '$0',
      borderBottomWidth: '$1',
      borderColor: '$none',
      position: 'absolute',
      inset: '$0',
    },
    '&:not([data-disabled]):hover:after, &:not([data-disabled]):focus:after': {
      backgroundColor: '$w11',
    },
    '&:not([data-disabled]):hover, &:not([data-disabled]):focus': {
      backgroundColor: '$w9',
    },
    '&:not(:last-child):before': {
      borderColor: '$w11',
      borderTopWidth: '$0',
      borderBottomWidth: '$1',
      borderRightWidth: '$0',
      borderLeftWidth: '$0',
    },
    '&[data-disabled]': {
      color: '$gs8',
    },
  },
  variants: {
    type: {
      img: {
        p: '$0',
      },
      text: {
        py: '$2',
        px: '$4',
      },
      item: {},
    },
  },
  defaultVariants: {
    type: 'text',
  },
})

export const dropdownMenuContentInner = css({
  overflowX: 'auto',
  boxShadow:
    '[{sizes.$0} {sizes.$3} {sizes.$10} -{sizes.$3} {colors.$b7}, {sizes.$0} {sizes.$3} {sizes.$5} -{sizes.$4} {colors.$b9}]',
  // additional css properties
  width: 'auto',
  top: '$5',
  right: '$3',
  zIndex: '[1]',
  display: 'none',
  // variant
  color: '$gs12',
})

const dropdownMenuItemInner = css({
  position: 'relative',
  display: 'flex',
  cursor: 'pointer',
  py: '$2',
  px: '$4',
  alignItems: 'center',
  outline: 'none',
  _active: {
    content: '" "',
    top: '$0',
    left: '$0',
    right: '$0',
    bottom: '$0',
    borderWidth: '[2px]',
    borderStyle: 'solid',
    borderColor: '$sec',
    backgroundColor: '$gs6',
  },
  _before: {
    content: '" "',
    borderStyle: 'solid',
    borderWidth: '$0',
    borderBottomWidth: '$1',
    borderColor: '$none',
    position: 'absolute',
    mx: '$1',
    inset: '$0',
  },
  _after: {
    content: '" "',
    borderStyle: 'solid',
    borderWidth: '$0',
    borderBottomWidth: '$1',
    borderColor: '$none',
    position: 'absolute',
    inset: '$0',
  },
  '&[data-disabled]': {
    color: '$gs8',
  },
  '&:not([data-disabled]):hover:after, &:not([data-disabled]):focus:after': {
    backgroundColor: '$w11',
  },
  '&:not([data-disabled]):hover, &:not([data-disabled]):focus': {
    backgroundColor: '$w9',
  },
  '&:not(:last-child):before': {
    borderColor: '$w11',
    borderWidth: '$0',
  },
})

// const DropdownMenuTrigger = styled(DropdownMenuPrimitive.Trigger, {
const outerTrigger = css({
  background: '$none',
  borderWidth: '$0',
  cursor: 'pointer',
  '&*': {
    cursor: 'pointer !important',
  },
  _focusVisible: {
    outline: 'none',
  },
})

const container = css.raw({
  // have to place this here to wrap the DropdownMenuContentContainer
  '&[data-side="top"]': {
    borderTopRadius: '$4',
    borderRightRadius: '$4',
    borderBottomRadius: '$0',
    borderLeftRadius: '$0',
  },
  '&[data-side="bottom"]': {
    borderTopRadius: '$0',
    borderRightRadius: '$0',
    borderBottomRadius: '$4',
    borderLeftRadius: '$4',
  },
})

const textStyled = css.raw({
  flex: '1',
  pr: '$2',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

// const DropdownMenuTriggerItem = styled(DropdownMenuPrimitive.Trigger, {
const innerTrigger = css({
  position: 'relative',
  display: 'flex',
  cursor: 'pointer',
  py: '$2',
  px: '$4',
  alignItems: 'center',
  outline: 'none',
  _active: {
    content: '" "',
    inset: '$0',
    borderWidth: '[2px]',
    borderStyle: 'solid',
    borderColor: '$sec',
    backgroundColor: '$gs6',
  },
  _before: {
    content: '" "',
    position: 'absolute',
    inset: '$0',
    borderStyle: 'solid',
    borderWidth: '[2px]',
    borderColor: '$sec',
    opacity: 0,
    visibility: 'hidden',
    transitionProperty: 'opacity',
    transitionDuration: '$fast',
    transitionTimingFunction: 'linear',
  },
  '&[data-state="open"]:before': {
    opacity: 1,
    visibility: 'visible',
  },
  '&[data-state="open"]': {
    backgroundColor: '$gs6',
  },
})

const dropdownMenuSeparator = css({
  height: '[2px]',
  backgroundColor: '$gs6',
})

export const dropdownIcon = {
  pr: '$2',
} as const
